<template>
  <div>
    <h1>Redirecting...</h1>
  </div>
</template>
<script>

export default {

  created() {
    window.location.href = 'https://trustvpn.vpngn.com/crm/';
  },

};
</script>
